import {createTheme} from '@mui/material/styles';


const IDEPrimaryColor = '#494FE3';

export const themeIDE = createTheme({
    components: {

        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'white',
                    fontWeight: '400',
                    textTransform: 'none',
                    borderRadius: '10px',
                    '&.Mui-disabled': {
                        color: '#fff',
                        backgroundColor: IDEPrimaryColor,
                        opacity: '50%',
                    },
                },
                sizeSmall: {
                    height: '24px',
                    fontSize: '14px',
                },
                sizeMedium: {
                    fontSize: '16px',
                },
                sizeLarge: {
                    height: '50px',
                    fontSize: '16px',
                },
                outlined: {
                    borderWidth: '2px',
                    borderColor: IDEPrimaryColor,
                    '&:hover': {
                        borderColor: '#33379e',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    gap: '26px',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: 'rgba(255, 255, 255, 0.7)', // Цвет текста табов с 70% непрозрачностью
                    '&.Mui-selected': {
                        color: '#ffffff',
                        borderBottom: '1px solid',
                    },
                },
            },
        },
    },
    // Add other theme customizations like typography, palette, etc.
    palette: {
        mode: 'dark',
        // primary: {
        //   main: '#3f51b5',
        // },
        // secondary: {
        //   main: '#f50057',
        // },
        primary: {
            main: IDEPrimaryColor,
        },
        success: {
            main: '#40A847',
        },
        error: {
            main: '#BB5252',
        },
        background: {
            default: '#2E3235',
            paper: '#2E3235',
        },
    },
});
