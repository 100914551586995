import { Box, SxProps, Theme } from '@mui/material';

interface LoaderProps {
    size?: number;
    altText?: string;
    sx?: SxProps<Theme>;
}
const Loader: React.FC<LoaderProps> = ({
    size = 150,
    altText = 'Loading...',
    sx = {},
}) => {
    const gifPath = '/loading.gif';
    return (
        <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...sx,
        }}
        >
            <img
                src={gifPath}
                alt={altText}
                style={{
                    width: `${size}px`,
                    height: `${size}px`,
                }}
            />
        </Box>
    );
};

export default Loader;
