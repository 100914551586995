import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
    Avatar,
    TextField,
    Tooltip,
    Paper,
    InputAdornment,
    Typography,
} from '@mui/material';
import { indigo, green, red, grey } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';
import MoodIcon from '@mui/icons-material/Mood';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import useChatPrompt from '../hooks/useChatPrompt';
import Loader from './Loader';
import { PumpingTask, TaskFile, TaskSubmissionOutput } from '../types';

interface GPTChatProps {
    taskSubmission: TaskSubmissionOutput | undefined;
    loadingResult: boolean;
    currentTask: PumpingTask;
    setCurrentTask: React.Dispatch<
        React.SetStateAction<PumpingTask | undefined>
    >;
    selectedFile: TaskFile;
    setSelectedFile: React.Dispatch<React.SetStateAction<TaskFile | undefined>>;
    applyTask: (
        currentTask: PumpingTask | undefined,
        selectedFilePath: string | undefined
    ) => Promise<void>;
}

const GPTChat: React.FC<GPTChatProps> = ({
    taskSubmission,
    loadingResult,
    currentTask,
    setCurrentTask,
    selectedFile,
    setSelectedFile,
    applyTask,
}) => {
    const [textError, setTextError] = useState<boolean>(false);
    const [placeholder, setPlaceholder] = useState<string | undefined>(
        undefined
    );
    const [chatPrompt, setChatPrompt] = useState<string | undefined>(undefined);
    const [shouldSendMessage, setShouldSendMessage] = useState(false);

    const receivedChatPrompt = useChatPrompt();

    useEffect(() => {
        if (receivedChatPrompt) {
            setChatPrompt(receivedChatPrompt);
            handleFileChange(receivedChatPrompt);
            setShouldSendMessage(true);
        }
    }, [receivedChatPrompt]);

    useEffect(() => {
        if (shouldSendMessage) {
            setShouldSendMessage(false);
            handleSendMessage();
        }
    }, [currentTask.files, shouldSendMessage]);

    useEffect(() => {
        if (currentTask.files.length > 0) {
            const starterFile: TaskFile | undefined = currentTask.files.find(
                (file) =>
                    file.path === 'prompt.txt' || file.path === 'starter.txt'
            );
            const placeholderFile: TaskFile | undefined =
                currentTask.files.find(
                    (file) => file.path === 'placeholder.txt'
                );
            if (starterFile) {
                setSelectedFile(starterFile);
                setChatPrompt(starterFile.content);
            }
            if (placeholderFile) {
                setPlaceholder(placeholderFile.content);
            }
        }
    }, [currentTask, setSelectedFile, selectedFile]);

    const handleSendMessage = async () => {
        if (!chatPrompt?.trim()) {
            setTextError(true);
            return;
        }
        setTextError(false);
        // Сохраняем chatPrompt в текущем файле только перед отправкой
        const updatedFiles = currentTask.files.map((file) =>
            file.path === selectedFile.path
                ? { ...file, content: chatPrompt }
                : file
        );
        const updatedCurrentTask: PumpingTask = { ...currentTask, files: updatedFiles };
        setCurrentTask(updatedCurrentTask);
        await applyTask(updatedCurrentTask, selectedFile.path);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.ctrlKey && event.key === 'Enter') {
            handleSendMessage();
        }
    };

    const handleInputChange = (value: string) => {
        setTextError(false);
        setChatPrompt(value);
    };

    const handleFileChange = (value: string) => {
        setTextError(false);
        if (!currentTask.files) {
            return;
        }
        const updatedFiles = currentTask.files.map((file) => {
            if (file.path === selectedFile.path) {
                return { ...file, content: value };
            }
            return file;
        });
        setCurrentTask({ ...currentTask, files: updatedFiles });
    };

    const messagesLib = {
        teacher: {
            inputLabel: 'Write your message',
            emptyInputError: 'Message cannot be empty',
            resultPlaceholder: 'I’m ready to review',
            chtName: 'Дуду',
        },
        chat: {
            inputLabel: 'Напиши свой запрос',
            emptyInputError: 'Запрос не может быть пустым',
            resultPlaceholder: 'Чем могу помочь?',
            chtName: 'Дуду',
        },
    };

    var resultColor: string = '#ECEFF0';
    var messages = messagesLib.chat;
    if (currentTask?.framework === 'teacher') {
        messages = messagesLib.teacher;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2.5}
            py={2}
            height="100vh"
            width="100%"
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={1}
                width="100%"
            >
                <Box display="flex" alignItems="center" width="100%" gap={2}>
                    <TextField
                        fullWidth
                        multiline
                        onKeyDown={handleKeyPress}
                        variant="outlined"
                        error={textError}
                        label={messages.inputLabel}
                        placeholder={textError ? messages.emptyInputError : ''}
                        value={chatPrompt || ''}
                        onChange={(e) => handleInputChange(e.target.value)}
                        // helperText={
                        //     textError ? messages.emptyInputError : placeholder
                        // }
                        minRows={2}
                        maxRows={6}
                        sx={{
                            fontSize: '14px',
                            '& fieldset': {
                                outline: 'none',
                                border: 'none',
                            },
                            '& textarea': {
                                color: '#2E3235',
                                fontSize: {
                                    xs: '12px',
                                    sm: '18px',
                                },
                                '&::placeholder': {
                                    color: textError
                                        ? '#BB5252'
                                        : 'rgb(73, 79, 277, 50%)',
                                },
                            },
                        }}
                        slotProps={{
                            inputLabel: {
                                sx: {
                                    fontSize: {
                                        xs: '12px',
                                        sm: '18px',
                                    },
                                    color: textError ? '#DEA9A9' : '#2E3235',
                                    fontWeight: 'regular',
                                    '&.MuiInputLabel-shrink': {
                                        color: textError
                                            ? '#DEA9A9'
                                            : 'rgb(73, 79, 277, 50%)',
                                        background: '#fff',
                                        fontWeight: '600',
                                        padding: '0 10px',
                                        top: {
                                            xs: '4px',
                                            sm: '0',
                                        },
                                        left: {
                                            xs: '14px',
                                            sm: '24px',
                                        },
                                    },
                                },
                            },
                            input: {
                                sx: {
                                    padding: {
                                        xs: '13px 13px 13px 8px',
                                        sm: '20px 41px 15px 24px',
                                    },
                                    flexDirection: 'column',
                                    borderRadius: '15px',
                                    border: textError
                                        ? '3px solid #DEA9A9'
                                        : ' 3px solid #ECEFF0',
                                    gap: '9px',
                                    '&:focus': {
                                        border: textError
                                            ? '3px solid #DEA9A9'
                                            : ' 3px solid rgb(73, 79, 277, 50%)',
                                        outline: 'none',
                                    },
                                    '&.Mui-focused': {
                                        border: textError
                                            ? '3px solid #DEA9A9'
                                            : ' 3px solid rgb(73, 79, 277, 50%)',
                                        outline: 'none',
                                    },
                                },
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        sx={{
                                            alignSelf: 'flex-end',
                                            maxHeight: 'none',
                                        }}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="right"
                                            marginBottom={0}
                                            gap={1}
                                        >
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: '#838889',
                                                    fontWeight: '600',
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'flex',
                                                    },
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    textAlign: 'right',
                                                    boxShadow: 'none',
                                                }}
                                            >
                                                Ctrl + Enter
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                endIcon={
                                                    <SendIcon
                                                        sx={{ marginRight: 1 }}
                                                    />
                                                }
                                                onClick={handleSendMessage}
                                                fullWidth
                                                sx={{
                                                    minHeight: {
                                                        xs: '23px',
                                                        sm: '33px',
                                                    },
                                                    borderRadius: {
                                                        xs: '5px',
                                                        sm: '10px',
                                                    },
                                                    background: '#494FE3',
                                                    width: {
                                                        xs: '129px',
                                                        sm: '300px',
                                                    },
                                                    textTransform: 'none',
                                                    fontSize: {
                                                        xs: '14px',
                                                        sm: '18px',
                                                    },
                                                    fontWeight: {
                                                        xs: 'regular',
                                                        sm: 'medium',
                                                    },
                                                    padding: 0,
                                                    flexDirection:
                                                        'row-reverse',
                                                    gap: {
                                                        xs: '5px',
                                                        sm: '10px',
                                                    },
                                                    boxShadow: 'none',
                                                }}
                                            >
                                                Отправить
                                            </Button>
                                        </Box>
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                </Box>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                width="100%"
                flexGrow={1}
                overflow="hidden"
                sx={{
                    gap: { xs: '9px', sm: '13px' },
                }}
            >
                <Tooltip title="Doudou AI" placement="left">
                    <Avatar
                        alt="Doudou AI"
                        sx={{
                            width: { xs: 44, sm: 82 },
                            height: { xs: 44, sm: 82 },
                            bgcolor: indigo[50],
                            alignSelf: 'flex-start',
                            border:
                                currentTask?.framework === 'teacher' &&
                                taskSubmission
                                    ? taskSubmission.status === 'success'
                                        ? `3px solid ${green[500]}`
                                        : `0px solid ${red[500]}`
                                    : `0px solid transparent`,
                        }}
                        src="/doudou.png"
                    />
                </Tooltip>
                <Paper
                    variant="outlined"
                    sx={{
                        height: '100%',
                        overflowY: 'auto',
                        paddingX: 1,
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word',
                        minHeight: 150,
                        width: '100%',
                        border: '3px solid #ECEFF0',
                        backgroundColor: '#ECEFF0',
                        borderRadius: '15px',
                        padding: { xs: '12px', sm: '24px' },
                    }}
                >
                    {loadingResult ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                        >
                            <Loader
                                sx={{ marginTop: 8 }}
                            />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                // margin: 0,
                                '& p': {
                                    // margin: 0,
                                    fontSize: { xs: '12px', sm: '18px' },
                                    lineHeight: '1.5',
                                    color: '#2E3235',
                                    marginBottom: '17px',
                                },
                            }}
                        >
                            <Typography variant="h6" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                {messages.chtName}
                                {currentTask?.framework === 'teacher' && taskSubmission ? (
                                    taskSubmission.status === 'success' ? (
                                        <MoodIcon fontSize='large' color='success' />
                                    ) : taskSubmission.status !== 'success' ? (
                                        <MoodBadIcon fontSize='large' color='error' />
                                    ) : null
                                ) : null}
                            </Typography>
                            <Typography variant="body1" marginTop={1}>
                                {taskSubmission?.message || placeholder || messages.resultPlaceholder}
                            </Typography>
                        </Box>
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default GPTChat;
